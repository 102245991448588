import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../organisatieeffectiviteit.scss"
import kantoor from "../../images/incompany-training.jpg"
import bgcase from "../../images/zichtbaarresultaat.jpg"

const Incompany = () => {
  return (
    <Layout>
      <SEO
        title="Enneagram opleiding Zipperr style"
        description="Het enneagram biedt een samenhangende psychologische en spirituele structuur van negen patronen en geeft inzicht in onze 'innerlijke machine"
      />
      <div
        style={{
          width: "100vw",
          height: "250px",
          overflow: "hidden",
          backgroundImage: `url(${kantoor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*<img style={{width: '100vw'}} src={office} alt=""/>*/}
      </div>
      <div className="highlightedText containerPadModified">
        <div className="heading">
          <div>
            <h1 className={"subTitleContent centerText"}>
              Het enneagram biedt een samenhangende psychologische en spirituele
              structuur van negen patronen en geeft inzicht in onze 'innerlijke
              machine
            </h1>
          </div>
        </div>
        <div className="subhead">
          <p className=" afterlife">Enneagram opleiding Zipperr style</p>
        </div>
        <p>
          Wil jij als coach leren werken met het enneagram en tools krijgen
          waarmee je direct aan de slag kunt? Dan is de enneagram 3-daagse iets
          voor jou. Een compacte opleiding in een kleine groep die je kunt
          inzetten naast andere interventies die je al gebruikt. Het enneagram
          biedt een samenhangende psychologische en spirituele structuur van
          negen patronen en geeft inzicht in onze 'innerlijke machine'.
        </p>
      </div>
      <div className={"containerPad flexy"}>
        <div className={"half block contntr"}>
          <h2>Herken je dit?</h2>
          <ul>
            <li>
              Wat zit er achter het gedrag van mijn coachee, wat is de diepere
              laag?
            </li>
            <li>
              Hoe geef ik woorden en structuur aan wat ik waarneem bij mijn
              coachee?
            </li>
            <li>
              Hoe maak ik mijn coachee bewust van zijn patronen in denken,
              voelen en doen?
            </li>
            <li>
              Hoe geef ik mijn coachee de juiste handvatten om eigen regie te
              nemen in gedragsverandering?
            </li>
          </ul>
          <p>
            Het enneagram is een mooi en complex model met negen karakterstijlen
            en verschillende lagen. Als coach is het waardevol als je wat het
            model te zeggen heeft en hoe het werkt. Enneagram trainers Sabine en
            Mariëlle hebben hun kennis gebundeld in een zeer compacte opleiding
            van drie dagen, waarmee je een vliegende start krijgt in het
            inzetten van het enneagram in je coaching.
          </p>
          <p>
            In het enneagram zitten negen gedragspatronen die je coachee laten
            zien in welke valkuil hij steeds weer trapt en hoe hij zijn
            (onbewuste) gedachten en emoties hem beïnvloeden. De coachee kan
            zelf gaan herkennen wanneer hij in een (oud) patroon schiet en hoe
            hij er weer uit kan komen. Je leert hem nieuwe patronen aan, zodat
            hij zijn potentieel optimaal kan inzetten.
          </p>
          <h3>Begin nu met de Enneagram opleiding Zipperr style</h3>
          <p>
            <i>
              <b>Minmale inspanning, maximaal resultaat</b>
              <br />
              "Leer in drie dagen coachen met het enneagram. De opleiding is
              hoogwaardig en compact en je leert het enneagram combineren met
              andere interventies zoals systeemwerk."
            </i>
          </p>
          <b>Je krijgt in de opleiding:</b>
          <ul>
            <li>
              algemene kennis van het enneagram, het symbool en de mogelijkheden
              en beperkingen van dit model
            </li>
            <li>
              kennis van de karaktertypologie van het enneagram: kenmerken,
              kwaliteiten, valkuilen en basisbehoeften
            </li>
            <li>negen types in drie centra: denken, voelen en doen</li>
            <li>
              Rad van Gedrag met bewuste en onbewuste drijfveren (negen
              patronen)
            </li>
            <li>
              het kleurenpalet van je persoonlijkheid: interactie en samenhang
              tussen de verschillende types
            </li>
            <li>de types in optima forma en uit balans</li>
            <li>de ontwikkelingsmogelijkheden per type</li>
            <li>
              handzame overzichten die je in kan zetten in je coaching, zodat je
              er direct mee aan de slag kan
            </li>
          </ul>

          <h3
            style={{
              fontSize: "14px",
            }}
          >
            De kosten zijn gebaseerd op maatwerk! Laat je eens informeren!
          </h3>
          <p className={"btn-container"}>
            <Link
              to={"/contact"}
              className="btn primary-lg round expand-on-hover"
            >
              Aanmelden of meer informatie
            </Link>
          </p>
        </div>

        <div
          className={"half block imger"}
          style={{
            overflow: "hidden",
            backgroundImage: `url(${bgcase})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImageMobile: file(relativePath: { eq: "briefcase.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageLaptop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 600, height: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImageDesktop: file(relativePath: { eq: "case-edit.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 1200, height: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ziperCaseImg: file(relativePath: { eq: "ad-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Incompany
